$morado: #853074;
$max-width: 1000px;

html,
body,
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body {
	display: flex;
	height: 100vh;
	width: 100vw;
	flex-direction: column;
	font-family: 'Poppins', sans-serif;
	background-color: $morado;
	color: white;
}

h1 {
	font-size: 3rem;
	text-align: center;
}
h2 {
	font-size: 2rem;
	text-align: center;
}
p {
	font-size: 1.3rem;
	margin-bottom: 1rem;
	font-weight: lighter;
}
a {
	color: white;
	text-decoration: none;
	transition: color 0.3s ease;
	&:hover {
		color: black;
	}
}
ul,
ol {
	margin-left: 2rem;
	li {
		font-size: 1.3rem;
		margin-bottom: 1rem;
		font-weight: lighter;
	}
}

label {
	font-size: 1rem;
	font-weight: normal;
	margin-bottom: 0.5rem;
	display: block;
}
input,
textarea {
	border: 1px solid #dfdfdf;
	outline: none;
	background-color: transparent;
	color: black;
	padding: 0.5rem;
	margin-bottom: 1rem;
	width: 100%;
	&[type='submit'] {
		background-color: $morado;
		color: white;
		border: none;
		padding: 1rem 1rem;
		font-weight: bold;
		font-size: 1.3rem;
		cursor: pointer;
		transition: background-color 0.3s ease;
		&:hover {
			background-color: black;
		}
	}
}
.form {
	padding: 0 1rem;
	max-width: 600px;
	width: 100%;
	margin: 0 auto;
}

img {
	max-width: 100%;
}

header {
	background-color: $morado;
	position: fixed;
	width: 100%;
	padding: 1rem;
	opacity: 0.9;
	z-index: 1;

	.content {
		flex-direction: row;
		align-items: center;
	}

	.logo {
		width: 200px;
	}
	nav {
		ul {
			display: flex;
			gap: 1rem;
			list-style: none;

			li {
				a {
					text-decoration: none;
					color: white;
					font-weight: bold;
				}
			}
		}
	}
}

.content {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: space-between;
	margin: 0 auto;
	width: 100%;
	max-width: $max-width;
}

main {
	position: relative;
	width: 100%;
}
.anchor {
	display: block;
	position: relative;
	top: -150px;
	visibility: hidden;
}
.full-height {
	min-height: calc(100vh - 200px);
}
.only {
	display: flex;
	align-items: center;
	justify-content: center;
}
section {
	background-color: white;
	color: $morado;
	width: 100%;
	padding: 4rem 1rem;
	border-top: 2px solid #dfdfdf;
	a {
		color: $morado;
		text-decoration: none;
		transition: color 0.3s ease;
		&:hover {
			color: black;
		}
	}
}

footer {
	width: 100%;
	padding: 2rem 1rem;
	.content {
		display: flex;
		flex-direction: row;
		gap: 1rem;
	}
	p {
		margin: 0;
		font-size: 1rem;
	}
}

@media only screen and (max-width: 600px) {
	main {
		font-size: 10vw;
	}
}

#inicio {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $morado;
	color: white;

	.portada {
		max-width: 500px;
		margin: 0 auto;
		margin-bottom: 2rem;
	}

	.tags {
		list-style: none;
		padding-left: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		max-width: 500px;
		margin: 0 auto;

		li {
			--size: 4;
			font-size: calc(var(--size) * 0.25rem + 0.5rem);
			line-height: calc(var(--size) * 0.25rem + 0.5rem);
			display: block;
			padding: 0.125rem 1.25rem;
			position: relative;
			text-decoration: none;
		}

		li[data-weight='1'] {
			--size: 1;
		}
		li[data-weight='2'] {
			--size: 2;
		}
		li[data-weight='3'] {
			--size: 3;
		}
		li[data-weight='4'] {
			--size: 4;
		}
		li[data-weight='5'] {
			--size: 6;
		}
		li[data-weight='6'] {
			--size: 8;
		}
		li[data-weight='7'] {
			--size: 10;
		}
		li[data-weight='8'] {
			--size: 13;
		}
		li[data-weight='9'] {
			--size: 16;
		}
		&[data-show-value] li::after {
			content: ' (' attr(data-weight) ')';
			font-size: 1.5rem;
		}

		li:focus {
			outline: 1px dashed;
		}

		li::before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			width: 0;
			height: 100%;
			transform: translate(-50%, 0);
			opacity: 0.15;
			transition: width 0.25s;
		}

		li:focus::before,
		li:hover::before {
			width: 100%;
		}
		@media (prefers-reduced-motion) {
			ul {
				transition: none !important;
			}
		}
		@media (max-width: 768px) {
			li[data-weight='1'] {
				--size: 0.5;
			}
			li[data-weight='2'] {
				--size: 1;
			}
			li[data-weight='3'] {
				--size: 1.5;
			}
			li[data-weight='4'] {
				--size: 2;
			}
			li[data-weight='5'] {
				--size: 2.5;
			}
			li[data-weight='6'] {
				--size: 3;
			}
			li[data-weight='7'] {
				--size: 3.5;
			}
			li[data-weight='8'] {
				--size: 4;
			}
			li[data-weight='9'] {
				--size: 4.5;
			}
		}
	}
}

.first {
	padding-top: 100px;
}

.donde-estamos {
	.info {
		display: grid;
		grid-template-columns: 2fr 1fr;
	}
	.direccion {
		align-items: center;
		gap: 1rem;
		justify-content: space-between;
	}
	.direccion {
		p {
			margin-bottom: 1rem;
		}
		strong {
			min-width: 120px;
			display: inline-block;
		}
	}
	@media (max-width: 768px) {
		.info {
			grid-template-columns: 1fr;
		}
		.mapa {
			iframe {
				width: 100%;
			}
		}
	}
}

.sobre-nosotros {
	.fotos {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}
	@media screen and (max-width: 768px) {
		.fotos {
			grid-template-columns: 1fr;
		}
	}
}

.fondo {
	.logos {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		img {
			max-width: 200px;
			width: 100%;
		}
	}
	.implantacion {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-bottom: 2rem;
		img {
			max-width: 600px;
			width: 100%;
		}
	}
	@media (max-width: 768px) {
		.logos {
			flex-direction: column;
		}
	}
}
.politicas {
	p,
	li {
		font-size: 1rem;
	}
}

table {
	thead {
		display: table-header-group;
		vertical-align: middle;
		border-color: inherit;
	}
	tbody {
		display: table-row-group;
		vertical-align: middle;
		border-color: inherit;
	}
	tr {
	}
	td {
		padding: 0 0.5rem;
	}
	th {
		background: #ccc;
		text-align: center;
		padding: 0 0.5rem;
	}
}
#cookies {
	position: fixed;
	bottom: 10px;
	right: 10px;
	background: rgba($color: #b6b2b5cc, $alpha: 0.8);
	padding: 1rem;
	border-radius: 5px;
	color: black;
	max-width: 340px;
	.wrapper {
		margin: 0;
		padding: 0;
	}
	p {
		font-size: 0.8rem;
	}
	.botones {
		text-align: right;
		a,
		button {
			font-size: 1rem;
		}
		button {
			border: 0;
			background-color: #2e9248;
			color: white;
			font-size: 1rem;
			padding: 0.5rem 1rem;
			border-radius: 3px;
			margin-left: 1rem;
		}
	}
}

.glass {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(255, 255, 255, 0.3);
}
